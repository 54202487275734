import React, { useState, useEffect } from 'react';
import { BiPlanet, BiCustomize } from 'react-icons/bi';
import { Button, Col, Row } from 'react-bootstrap';
import StripeLoaderImg from '../stripe_loader.svg';
import CollectionABI from '../abis/CollectionABI';
import SETTINGS from '../SETTINGS';
import { BrowserProvider, Contract } from 'ethers'
import { useNavigate } from 'react-router-dom';

const LevelComponent = ({ provider, isConnected }) => {
    const [loading, setLoading] = useState(true);
    const [setTxMessage] = useState('');
    const [refferalCode, setRefferalCode] = useState('');
    const [userLevel, setUserLevel] = useState(1);
    const [levelModelUrl, setLevelModelUrl] = useState("https://node.qvrse.io/");
    const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
    const navigate = useNavigate();
    const openCraft = () => {
        navigate('/craft');
    }
    const openBuyNode = () => {
        navigate('/store');
    }
    const _setReffCode = async (code) => {
        setRefferalCode(code);
    };
    const getUserLevel = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        const level = await collectionContract.levels(signer.address);
        if (parseInt(level) > 1) {
            setUserLevel(level.toString());

        }
        if (parseInt(level) == 2) setLevelModelUrl("https://node1.qvrse.io/");
        if (parseInt(level) == 3) setLevelModelUrl("https://node2.qvrse.io/");
        if (parseInt(level) == 4) setLevelModelUrl("https://node3.qvrse.io/");
        if (parseInt(level) == 5) setLevelModelUrl("https://node4.qvrse.io/");
        if (parseInt(level) == 1337) setLevelModelUrl("https://node1337.qvrse.io/");
        setLoading(false);
    };

    useEffect(() => {
        if (isConnected) {
            getUserLevel();
        } else {
            setLoading(false);
        }
    }, [isConnected, provider]);


    if (loading) {
        return (
            <div className="pokemon-card">
                <div className="text-center">
                    <center>
                        <img src={StripeLoaderImg} />
                    </center>
                </div>
            </div>
        );
    }


    return (

        <div>
            <Row className='align-items-center'>
                <Col xs={6} md={12}>
                    <div className="iframe-container">
                        <iframe src={levelModelUrl} title="External Content" />
                    </div></Col>
                <Col xs={6} md={12} className='d-md-none d-flex'>
                    <Row className='mb-4'>
                        <Col xs={12} ><Button variant="primary buton mt-3 d-flex align-items-center justify-content-center" onClick={openBuyNode} className='buton'>
                            <BiPlanet className='me-1' /> SHOP
                        </Button></Col>
                        <Col xs={12} ><Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openCraft} className='buton'>
                            <BiCustomize className='me-1' /> CRAFT
                        </Button></Col>
                    </Row>
                </Col>
            </Row>
            <Col xs={6} md={12} className='d-md-block d-none mt-4 pt-1'>
                <Row className='mb-3'>
                    <Col><Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openBuyNode} className='buton'>
                        <BiPlanet className='me-1' /> SHOP
                    </Button></Col>
                    <Col><Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openCraft} className='buton' style={{ height: "42.5px", fontSize: "15px" }}>
                        <BiCustomize className='me-1' /> CRAFT
                    </Button></Col>
                </Row>
            </Col>
        </div>

    );
};

export default LevelComponent;
