import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SETTINGS from "./SETTINGS";

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId
const projectId = '6590f325db34cb32c9dd4e750269f2be'

const mainnet = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://polygon-rpc.com/'
}

// 3. Create a metadata object
const metadata = {
  name: 'Qvrse',
  description: 'Qvrse token dapp',
  url: 'https://token.qvrse.io/', // origin must match your domain & subdomain
  icons: ['https://node.qvrse.io/planet.png']
}

// 5. Create a Web3Modal instance
const web3Modal = createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    //enableEmail: true,
    enableEIP6963: true,
  }),
  chains: [mainnet],
  projectId,
  themeMode: 'light',
  themeVariables: {
    '--w3m-accent': '#944dff'
  },
  enableOnramp: true,

  enableAnalytics: true,
})

console.log('Web3Modal Initialized:', web3Modal);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
