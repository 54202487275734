import React, { useState, useEffect } from 'react';
import { Col, Card, Row, Image, Badge, Table } from 'react-bootstrap';
import SETTINGS from '../SETTINGS';
import NodeSaleAnnouncement from '../components/NodeSaleAnnouncement';
import CountdownTimer from '../components/CountdownTimer';
import { FaCoins, FaLayerGroup } from 'react-icons/fa';
import logoToken from '../logoToken.svg';

const WorldInfo = ({ provider, isConnected }) => {
  const [holderContracts, setHolderContracts] = useState([]);
  const [totalLocked, setTotalLocked] = useState(0);
  const [circulating, setCirculating] = useState(0);
  const [nodeInfoData, setNodeInfoData] = useState([]);
  const loadNodeArr = () => {
    var dict = SETTINGS.NODE_INFO
    var arr = [];
    for (var key in dict) {
      arr.push(dict[key]);
    }
    setNodeInfoData(arr);
  };
  const nodeData = [
    { idRange: '0 - 1999', price: '25 USDC' },
    { idRange: '2000 - 3999', price: '50 USDC' },
    { idRange: '4000 - 5999', price: '75 USDC' },
    { idRange: '6000 - 7999', price: '100 USDC' },
    { idRange: '8000 - 9999', price: '125 USDC' },
    { idRange: '10000 - 11999', price: '150 USDC' },
    { idRange: '12000 - 13999', price: '175 USDC' },
    { idRange: '14000 - 15999', price: '200 USDC' },
    { idRange: '16000 - 17999', price: '225 USDC' },
    { idRange: '18000 - 20000', price: '250 USDC' }
  ];
  useEffect(() => {
    loadNodeArr();
    if (!provider) return;

  }, [provider]);

  return (
    <div className="info-container">
      <Row className="g-4">
        {/* Title */}
        <h3 className='pageTitle pt-4 mb-0'>{SETTINGS.tokenSymbol} NODES</h3>
        <p className='d-flex justify-content-center align-items-center mb-4'>
          <small className='me-1'>{SETTINGS.tokenSymbol} generation ends in: </small>
          <CountdownTimer targetTimestamp={SETTINGS.END_TIME} />
        </p>
        <Col xs={12} xl={6} className='mt-0'>
          <Card className="mb-3 custom-card">
            <Card.Body>
              <NodeSaleAnnouncement />
            </Card.Body>
          </Card>
          <Card className="mb-4 custom-card">
            <Card.Body>
              <Card.Title><FaCoins className="me-2" />{SETTINGS.tokenSymbol} Nodes Pricing</Card.Title>
              <p>
                The price of QNODES increases as more QNODES are sold. This is the current pricing structure based on the QNODE ID range.
              </p>
              <div className="table-responsive mt-4">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID Range</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nodeData.map((node, index) => (
                      <tr key={index}>
                        <td>{node.idRange}</td>
                        <td><img src={SETTINGS.usdtIcon} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={SETTINGS.tokenSymbol} /> {node.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} xl={6} className='mt-0'>
          <Card className="mb-3 custom-card">
            <Card.Body>
              <Card.Title className='mb-3'><FaLayerGroup className="me-2" />QVRS Nodes and Elements</Card.Title>
              {nodeInfoData.map((node, index) => (
                <div className='pokemon-card mb-3 chat-message' key={index} style={{ padding: "10px 20px" }}>
                  <Row className="align-items-center">
                    {/* Img */}
                    <Col sm={3} xs={8} className='offset-sm-0 offset-2'>
                      <h5 className='text-uppercase mb-2 mt-0'>{node.title}</h5>
                      <Image src={node.imgUrl} rounded className="w-100" />

                    </Col>
                    {/* Price / Items needed */}

                    <Col sm={4}>
                      {node.price > 0 ? (
                        <div>
                          <small><b>Buy with:</b></small>
                          <p>
                            <img src={node.buyCurrency == "USDC" ? SETTINGS.usdtIcon : logoToken} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={SETTINGS.tokenSymbol} />
                            {node.price} {node.buyCurrency}</p>
                        </div>
                      ) : (
                        <div>
                          <small><b>Craft with:</b></small><br />
                          {node.craftElements.map((imgMod, index1) => (
                            <>
                              <Image key={index1} src={imgMod} rounded className="me-2 mb-2" style={{ width: '40px', height: '40px' }} />
                              {index1 == 1 && (<br />)}
                            </>
                          ))}
                        </div>
                      )}
                      {/* Rewards */}
                      <Row>
                        {node.dailyRewards > 0 && (
                          <Col xs={12} lg={6} className="mt-2 d-none">
                            <Badge pill bg="primary" className=' mb-2 pill-blue'>
                              <small>Daily: </small>{node.dailyRewards.toFixed(2)} {SETTINGS.tokenSymbol}
                            </Badge>
                          </Col>
                        )}
                        <Col xs={12} lg={12} className="mt-2 d-none">
                          <Badge pill bg="info" className='d-block mb-2'>
                            <small>Referral: </small>{node.refferalPercent} %
                          </Badge>
                        </Col>
                        {node.airdrop_power > 0 && (
                          <Col xs={12} lg={6} className="mt-2 d-none">
                            <Badge pill bg="primary" className=' mb-2 pill-pink'>
                              <small>Airdrop power: </small>{node.airdrop_power}
                            </Badge>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col sm={4}>

                      <Badge pill bg="info" className='d-block mb-2'>
                        <small>Referral: </small>{node.refferalPercent} %
                      </Badge>
                      <Badge pill bg="primary" className='d-block  mb-2 pill-blue'>
                        <small>Daily: </small>{node.dailyRewards.toFixed(2)} {SETTINGS.tokenSymbol}
                      </Badge>
                      <Badge pill bg="primary" className=' mb-2 pill-pink'>
                        <small>Airdrop power: </small>{node.airdrop_power}
                      </Badge>
                    </Col>
                  </Row>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>



      </Row>
    </div>
  );
};

export default WorldInfo;
