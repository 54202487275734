import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import SETTINGS from '../SETTINGS';

const FAQs = () => {
  return (
    <Container className="px-0 mt-0">
      <Row className="mb-5">
          <h3 className='pageTitle mb-4 pb-1'>FAQs</h3>
        <Col xs={12} className="mx-auto">
          <Row>
            <Col lg={6} xs={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="mb-2">
                  <Accordion.Header>What are QNODES and Elements?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                    QNODES and Elements are digital assets (NFTs) that can be purchased and crafted through the Qvrse token dapp. These assets are essential for advancing to higher levels and unlocking greater benefits. When you buy QNODES, you acquire a specific amount of Qvrse tokens, allowing you to earn daily rewards. These rewards are vested, meaning you can claim them daily to enhance your engagement and returns within the platform.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="mb-2">
                  <Accordion.Header>How can I craft a QNODE?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      To craft a QNODE, begin by purchasing Level 1 QNODE. The initial price of a QNODE is 25 USDC, for every 2,000 nodes created, the price increases. In total, there are 20,000 QNODES available. For crafting advanced-level QNODES, gather the necessary items specified in the Craft Book. Once crafted, a QNODE may provide various benefits, Qvrse tokens, increased referral bonuses, and enhanced airdrop rewards. These benefits are specific to each QNODE and are detailed in the Craft Book. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="mb-2">
                  <Accordion.Header>What is a Craft Book?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The Craft Book is a catalogue of digital worlds, known as Qnodes and Elements, that users can craft using specific in-game elements and resources. Each QNODE has unique requirements and benefits associated with crafting it. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="mb-2">
                  <Accordion.Header>What is the purpose of QNODE's purchase and crafting in this ecosystem?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Purchasing QNODES contributes to the liquidity essential for trading with the Qvrse token on decentralized exchanges (DEXs). Crafting involves merging QNODES to create new and unique items or features within the platform, enhancing your interaction within the ecosystem. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="mb-2">
                  <Accordion.Header>What are the benefits of crafting higher-level QNODES?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Higher-level QNODES generally offer greater daily {SETTINGS.tokenSymbol} rewards, larger referral percentages, and stronger airdrop powers, enhancing your overall benefits and influence within the ecosystem.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="mb-2">
                  <Accordion.Header>How does the purchase on Qvrse token dapp work?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Payment for QNODES can be made using USDC, while Elements are purchased using the platform’s native cryptocurrency {SETTINGS.tokenSymbol}. Users need to ensure they have sufficient balance in their wallets to complete the transactions.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="14" className="mb-2">
                  <Accordion.Header>How does the referral system work?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The referral system rewards you with a bonus percentage of each transaction made by users you've invited to the platform. When your referrals make a purchase, you earn a specified percentage of their total spend in USDC. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col lg={6} xs={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="7" className="mb-2">
                  <Accordion.Header>How can I acquire {SETTINGS.tokenSymbol} tokens?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      {SETTINGS.tokenSymbol} tokens can be acquired through direct purchase in this dapp or through earning mechanisms within the platform, such as airdrops and daily {SETTINGS.tokenSymbol} rewards.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8" className="mb-2">
                  <Accordion.Header>What is the role of {SETTINGS.tokenSymbol} tokens in the ecosystem?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      {SETTINGS.tokenSymbol} tokens are the platform’s native currency. They are utilized for purchasing QNODES, crafting elements, staking, participating in liquidity pools and used as a
                      payment method for services and products within the Qvrse ecosystem.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9" className="mb-2">
                  <Accordion.Header>What is the role of purchasing the elements in the ecosystem?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Purchasing elements within the ecosystem contribute to the Qvrse Airdrop pool. The more elements the community buys, the greater the quantity of Qvrse tokens accumulated in the pool, enhancing the overall value and rewards for participants. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10" className="mb-2">
                  <Accordion.Header>Can I stake all my {SETTINGS.tokenSymbol} tokens?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Yes, you can stake any portion of your {SETTINGS.tokenSymbol} tokens up to the total amount in your balance. You can use the "Max" button to automatically fill in the staking amount field with all your available tokens.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11" className="mb-2">
                  <Accordion.Header>How can I claim the staking reward?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      When the staking period ends, you can claim your original staked tokens plus any earned rewards. Use the "CLAIM" button corresponding to the completed stake to transfer both the staked amount and the rewards back to your balance.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12" className="mb-2">
                  <Accordion.Header>How are staking rewards calculated?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Staking rewards are calculated based on the APY, the amount of {SETTINGS.tokenSymbol} tokens staked, and the duration of the stake. Rewards accrue over the staking period and are available for claim at the end of the term.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13" className="mb-2">
                  <Accordion.Header>What is the Airdrop Power?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Airdrop Power (AP) represents your ability to receive airdrops within the platform. A higher AP value typically means you have a greater chance of receiving more substantial or frequent airdrops. 
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQs;