import React, { useState, useEffect } from 'react';
import { Button, Accordion } from 'react-bootstrap';
import copyImg from '../copy.png';
import StripeLoaderImg from '../stripe_loader.svg';
import CollectionABI from '../abis/CollectionABI';
import SETTINGS from '../SETTINGS';
import { BrowserProvider, Contract } from 'ethers'
import { useNavigate } from 'react-router-dom';
import { BiLike } from 'react-icons/bi';

const RefferalComponent = ({ provider, isConnected }) => {
    const [loading, setLoading] = useState(true);
    const [copying, setCopying] = useState(false);
    const [setTxMessage] = useState('');
    const [refferalCode, setRefferalCode] = useState('');
    const [userLevel, setUserLevel] = useState(1);
    const [userPercent, setUserPercent] = useState(0);
    const [userCode, setUserCode] = useState('');
    const [levelModelUrl, setLevelModelUrl] = useState("https://node.qvrse.io/");
    const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
    const navigate = useNavigate();
    const openCraft = () => {
        navigate('/craft');
    }
    const openBuyNode = () => {
        navigate('/store');
    }
    const copyCode = () => {
        setCopying(true);
        navigator.clipboard.writeText("https://token.qvrse.io/ref/index.php?code=" + userCode);
        setTimeout(() => setCopying(false), 2000);
    }
    const _setReffCode = async (code) => {
        setRefferalCode(code);
    };
    const getUserLevel = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        const level = await collectionContract.levels(signer.address);
        if (parseInt(level) > 1) {
            setUserLevel(level.toString());
            const code = await collectionContract.refferalCode(signer.address);
            setUserCode(code);
            if (code != "") {
                const percent = await collectionContract.getRefferalPercent(signer.address);
                setUserPercent(percent.toString());
            }
        }
        if (parseInt(level) == 2) setLevelModelUrl("https://node1.qvrse.io/");
        if (parseInt(level) == 3) setLevelModelUrl("https://node2.qvrse.io/");
        if (parseInt(level) == 4) setLevelModelUrl("https://node3.qvrse.io/");
        if (parseInt(level) == 5) setLevelModelUrl("https://node4.qvrse.io/");
        if (parseInt(level) == 1337) setLevelModelUrl("https://node1337.qvrse.io/");
        setLoading(false);
    };
    const createCode = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        try {
            setLoading(true);
            const collectionContract = new Contract(
                SETTINGS.collection,
                CollectionABI,
                signer
            );
            // Connect to the NFT contract and buy the NFT
            if (refferalCode.length <= 5) {
                alert("Should be longer than 5 characters.");
                setLoading(false);
                return;
            }
            const codeCheck = await collectionContract.codeUser(refferalCode.toUpperCase());
            if (codeCheck != "0x0000000000000000000000000000000000000000") {
                alert("Code is taken");
                setLoading(false);
                return;
            }
            // Call the buy function on the NFT contract
            // Since we're only working with USDT, we don't need to send ETH value
            const refTx = await collectionContract.createRefferalCode(refferalCode.toUpperCase());
            await refTx.wait();
        }
        catch (error) {
            console.error("Error creating code:", error);
        }
        finally {

            getUserLevel();
            // Optionally reset the transaction message after a delay
            setTimeout(() => setLoading(false), 2000);
        }
    };
    useEffect(() => {
        if (isConnected) {
            getUserLevel();
        } else {
            setLoading(false);
        }
    }, [isConnected, provider]);


    if (loading) {
        return (
            <div className="pokemon-card">
                <div className="text-center">
                    <center>
                        <img src={StripeLoaderImg} style={{ width: "40%" }} />
                    </center>
                </div>
            </div>
        );
    }


    return (
        <Accordion defaultActiveKey="1" className='refAccordion'>
            <Accordion.Item eventKey="2" className="my-0">
                <Accordion.Header>REFERRAL</Accordion.Header>
                <Accordion.Body>
                        <div className="text-center mb-2">
                            {userLevel <= 1 ? (<div className="pokemon-card" >
                                <div className="text-center" style={{ margin: "0px 0px" }}>
                                    <center>
                                        <p className='small mb-0'>You can create your referral link when you reach Level 2.</p>
                                    </center>
                                </div>
                            </div>
                            ) : (
                                <>
                                    {userCode == "" ? (
                                        <div className=''>
                                            <div className='input-group' style={{ marginBottom: "14px" }}>
                                                <label className='mb-1'>Create referral code</label>
                                                <input
                                                    type="text"
                                                    value={refferalCode}
                                                    onChange={(e) => _setReffCode(e.target.value)}
                                                    placeholder="Referral Code"
                                                    className='custom-select'
                                                />
                                            </div>
                                            <Button className='buton buton buton-secondary d-lg-block' onClick={createCode}>CREATE</Button>
                                        </div>
                                    ) : (
                                        <>
                                            <p className='small mt-2'>** Invite others and get rewarded when they purchase a QNODE!</p>
                                            <h6 className='mt-1'>Referral percent: {userPercent}%</h6>
                                            {copying ? (
                                                <Button variant="primary mt-3 d-dlex justify-content-center align-items-center" className='buton' >
                                                    <BiLike style={{ fontSize: "20px" }} />
                                                </Button>
                                            ) : (
                                                <Button variant="primary mt-3" onClick={copyCode} className='buton' >
                                                    <img src={copyImg} style={{ width: "13px", marginRight: "5px", filter: "", opacity: "1" }} />
                                                    {userCode}
                                                </Button>
                                            )}
                                        </>)}
                                </>)}
                        </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default RefferalComponent;
