import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Alert } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import Select from 'react-select';
import TokenSaleABI from '../abis/TokenSaleABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import logoToken from '../logoToken.svg';
import { useNavigate } from 'react-router-dom';
import ConnectButton from '../components/ConnectButton';
import clockIcon from '../clock.gif';

const TokenSale = ({ provider, account, isConnected }) => {
    const [ethBalance, setEthBalance] = useState('0');
    const [usdtBalance, setUsdtBalance] = useState('0');
    const [tokenBalance, setTokenBalance] = useState('0');
    const [presales, setPresales] = useState([]);
    const [selectedPresaleIndex, setSelectedPresaleIndex] = useState(0);
    const [amountToBuy, setAmountToBuy] = useState('');
    const [amountToReceive, setAmountToReceive] = useState('');
    const [activePresale, setActivePresale] = useState(null);
    const [activePresaleMatic, setActivePresaleMatic] = useState(0);
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState('USDC'); // Default currency is USDC
    const navigate = useNavigate();


    async function getBalance() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        // The Contract object
        const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
        const USDTBalance = await USDTContract.balanceOf(account);
        const USDTDecimals = await USDTContract.decimals();
        setUsdtBalance(formatUnits(USDTBalance, USDTDecimals));
        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));
        const balance = await ethersProvider.getBalance(account);
        setEthBalance(parseFloat(formatUnits(balance, 18)).toFixed(4));

    }

    useEffect(() => {
        const fetchPresales = async () => {
            if (!provider || !account) return;
            const ethersProvider = new BrowserProvider(provider);
            const contract = new Contract(SETTINGS.tokenSaleAddress, TokenSaleABI, ethersProvider);
            const pArr = await contract.getAllPresales();
            let data = [];
            for (let i = 0; i < pArr.length; i++) {
                const leftAmount = parseFloat(formatUnits(pArr[i].tokenAmount.toString(), 18).toString()) - parseFloat(formatUnits(pArr[i].soldAmount.toString(), 18).toString());
                data.push({
                    tokenAmount: pArr[i].tokenAmount.toString(),
                    tokenAmountDec: formatUnits(pArr[i].tokenAmount.toString(), 18).toString(),
                    price: pArr[i].price.toString(),
                    priceDec: formatUnits(pArr[i].price.toString(), 6).toString(), // USDC has 6 decimals
                    soldAmount: pArr[i].soldAmount.toString(),
                    leftAmount: leftAmount.toFixed(0),
                    isActive: pArr[i].isActive,
                    startTime: new Date(parseInt(pArr[i].startTime.toString()) * 1000).toLocaleString()
                });
            }
            setPresales(data);
            const active = data.find((presale) => presale.isActive);
            setActivePresale(active);

            // Get USDC amount from 1 MATIC
            const usdcAmountFromMatic = await contract.getUsdcAmountFromMatic(parseUnits("1", 18));
            const usdcAmountFromMaticDec = parseFloat(formatUnits(usdcAmountFromMatic, 6).toString());

            // Calculate token price in MATIC for each presale
            const dataWithMaticPrice = data.map((presale) => {
                const tokenPriceInUsdc = parseFloat(presale.priceDec);
                const tokenPriceInMatic = tokenPriceInUsdc / usdcAmountFromMaticDec;
                return {
                    ...presale,
                    priceInMatic: tokenPriceInMatic.toFixed(6) // or any desired precision
                };
            });

            setPresales(dataWithMaticPrice);

            if (active) {
                const activeTokenPriceInUsdc = parseFloat(active.priceDec);
                const activeTokenPriceInMatic = activeTokenPriceInUsdc / usdcAmountFromMaticDec;
                setActivePresaleMatic(activeTokenPriceInMatic.toFixed(6));
                setSelectedPresaleIndex(data.indexOf(active));
            }
        };

        if (isConnected) {
            fetchPresales();
            getBalance();
        }
    }, [provider, account, isConnected]);


    const _setAmountToBuy = async (amount) => {
        setAmountToBuy(amount);
        let tokensToReceive;
        if (selectedCurrency === 'USDC') {
            const price = parseFloat(activePresale.priceDec);
            tokensToReceive = parseFloat(amount) / price;
        } else if (selectedCurrency === 'MATIC') {
            // Fetch the MATIC to USDC conversion rate from the contract
            const ethersProvider = new BrowserProvider(provider);
            const contract = new Contract(SETTINGS.tokenSaleAddress, TokenSaleABI, ethersProvider);
            const usdcAmount = await contract.getUsdcAmountFromMatic(parseUnits(amount, 18));
            const price = parseFloat(activePresale.priceDec);
            tokensToReceive = parseFloat(formatUnits(usdcAmount, 6)) / price;
        }
        setAmountToReceive(tokensToReceive.toFixed(4));
    };

    const handleBuyToken = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        try {
            setLoading(true);
            setTxMessage("Approving USDC transfer...");
            const usdtContract = new Contract(
                SETTINGS.usdtAddress,
                ERC20_ABI,
                signer
            );
            // Convert the amount to buy to wei (USDC has 6 decimals)
            const amountToBuyWei = parseUnits(amountToBuy.toString(), 6);
            // Approve the token sale contract to spend USDC
            const approveTx = await usdtContract.approve(
                SETTINGS.tokenSaleAddress,
                amountToBuyWei
            );
            await approveTx.wait();
            setTxMessage("Purchasing tokens...");
            // Now call the buyToken function
            const tokenSaleContract = new Contract(
                SETTINGS.tokenSaleAddress,
                TokenSaleABI,
                signer
            );
            const buyTx = await tokenSaleContract.buyToken(
                selectedPresaleIndex,
                amountToBuyWei
            );
            await buyTx.wait();
            setTxMessage("Tokens purchased successfully!");
            console.log("Tokens purchased with USDC!");
            // Update UI after purchase
            // Fetch updated presales information, etc.
            getBalance();
            
        } catch (error) {
            console.error("Transaction failed!", error);
            setTxMessage("Transaction failed. Please try again.");
        } finally {
            setLoading(false);
            // Reset or update the message after a delay
            setTimeout(() => setTxMessage(""), 5000);
        }
    };

    const handleBuyTokenWithMatic = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        try {
            setLoading(true);
            setTxMessage("Purchasing tokens...");
            // Convert the amount to buy to wei
            const amountToBuyWei = parseUnits(amountToBuy.toString(), 18);
            // Now call the buyTokenWithMatic function
            const tokenSaleContract = new Contract(
                SETTINGS.tokenSaleAddress,
                TokenSaleABI,
                signer
            );
            const buyTx = await tokenSaleContract.buyTokenWithMatic(
                selectedPresaleIndex,
                { value: amountToBuyWei }
            );
            await buyTx.wait();
            setTxMessage("Tokens purchased successfully!");
            console.log("Tokens purchased with MATIC!");
            // Update UI after purchase
            // Fetch updated presales information, etc.
            getBalance();
            navigate('/');
        } catch (error) {
            console.error("Transaction failed!", error);
            setTxMessage("Transaction failed. Please try again.");
        } finally {
            setLoading(false);
            // Reset or update the message after a delay
            setTimeout(() => setTxMessage(""), 5000);
        }
    };
    // Define options with icons
    const currencyOptions = [
        {
            value: 'USDC',
            label: (
                <div style={{ alignItems: 'center' }}>
                    <center>
                        <img src={SETTINGS.usdtIcon} alt="USDC" style={{ width: '30px', marginRight: '5px' }} />
                    </center>
                </div>
            )
        },
        {
            value: 'MATIC',
            label: (
                <div style={{ alignItems: 'center' }}>
                    <center>
                        <img src={SETTINGS.nativeIcon} alt="MATIC" style={{ width: '30px', marginRight: '5px' }} />
                    </center>
                </div>
            )
        }
    ];

    // Custom styles for react-select to match the provided class
    const customSelectStyles = {
        control: (provided) => ({
            ...provided,
            fontSize: '15px',
            color: 'var(--tertiary)',
            border: '2px solid rgba(0, 0, 0, 0.1)',
            background: 'rgba(149, 77, 255, 0.01)',
            padding: '5px 8px',
            borderRadius: '8px',
            width: '120px',
            textAlign: 'center',
            transition: 'all 0.3s ease-in-out',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
    };

    const handleCurrencyChange = (selectedOption) => {
        setSelectedCurrency(selectedOption.value);
        setAmountToBuy('');
        setAmountToReceive('');
    };

    if (loading) {
        return (
            <div className=" text-center">
                <br />
                <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }

    return (
        <div>
            {/* Title */}
            <h3 className='pageTitle'>Buy {SETTINGS.tokenSymbol}</h3>
            {/* Row */}
            <Row className='mb-5'>
                <Col xs={12} md={8} lg={6} xl={5} className='offset-xl-2 offset-lg-1 offset-md-2  offset-0'>
                    {activePresale ? (
                        <div className='pokemon-card d-flex flex-column justify-content-between'>
                            <div>
                                {/* Token exchange info */}
                                <div className="token-exchange-info">
                                    {selectedCurrency === 'USDC' ? (
                                        <div>
                                            <img src={logoToken} style={{ width: "30px", marginRight: "6px", marginLeft: "5px", marginTop: "-5px" }} alt={SETTINGS.tokenSymbol} />
                                            1 {SETTINGS.tokenSymbol}&nbsp;&nbsp;&nbsp;=
                                            <img src={SETTINGS.usdtIcon} style={{ width: "30px", marginRight: "8px", marginLeft: "10px", marginTop: "-5px" }} alt={"USDC"} />
                                            {activePresale.priceDec} USDC
                                        </div>
                                    ) : (
                                        <div>
                                            <img src={logoToken} style={{ width: "30px", marginRight: "6px", marginLeft: "5px", marginTop: "-5px" }} alt={SETTINGS.tokenSymbol} />
                                            1 {SETTINGS.tokenSymbol}&nbsp;&nbsp;&nbsp;=
                                            <img src={SETTINGS.nativeIcon} style={{ width: "30px", marginRight: "8px", marginLeft: "10px", marginTop: "-5px" }} alt={"MATIC"} />
                                            {activePresaleMatic} MATIC
                                        </div>
                                    )}
                                </div>
                                {/* Swap form */}
                                <div className="swap-form">
                                    <div className="input-group" style={{ width: "100%" }}>
                                        <div className="d-flex align-items-center" style={{ width: "100%" }}>
                                            <input
                                                type="text"
                                                value={amountToBuy}
                                                onChange={(e) => _setAmountToBuy(e.target.value)}
                                                placeholder={`Amount in ${selectedCurrency}`}
                                                className='form-control'
                                                style={{ marginRight: "10px", flexGrow: 1 }}
                                            />
                                            <Select
                                                options={currencyOptions}
                                                value={currencyOptions.find(option => option.value === selectedCurrency)}
                                                onChange={handleCurrencyChange}
                                                styles={customSelectStyles}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group mb-2">
                                        <small style={{ marginTop: "1px" }}>You get:</small>
                                        <img src={logoToken} style={{ width: "18px", marginRight: "8px", marginLeft: "10px", marginBottom: "4px" }} alt={"Logo"} />
                                        <p className="mb-1 small fw-bold">{!amountToReceive || isNaN(amountToReceive) ? '0.0000' : amountToReceive}</p>
                                    </div>
                                </div>
                                <div className="gas-info mt-3 mb-4">
                                    *After purchase your {SETTINGS.tokenSymbol} tokens will be available in your wallet.
                                </div>
                            </div>
                            <div>
                                {selectedCurrency === 'USDC' ? (
                                    <Button className="buton" onClick={() => handleBuyToken()}>
                                        BUY WITH USDC
                                    </Button>
                                ) : (
                                    <Button className="buton" onClick={() => handleBuyTokenWithMatic()}>
                                        BUY WITH MATIC
                                    </Button>
                                )}
                                <center>
                                    {selectedCurrency === 'USDC' ? (
                                        <span style={{ marginTop: "16px", display: "block", fontWeight: "500" }}>
                                            <small>Your balance: </small>
                                            <img src={SETTINGS.usdtIcon} style={{ width: "18px", marginRight: "10px", marginLeft: "10px", marginTop: "-2px" }} alt={"USDC"} />
                                            <small className='fw-bold'>{usdtBalance} USDC</small>
                                        </span>
                                    ) : (
                                        <span style={{ marginTop: "16px", display: "block", fontWeight: "500" }}>
                                            <small>Your balance: </small>
                                            <img src={SETTINGS.nativeIcon} style={{ width: "18px", marginRight: "10px", marginLeft: "10px", marginTop: "-2px" }} alt={"MATIC"} />
                                            <small className='fw-bold'>{ethBalance} MATIC</small>
                                        </span>
                                    )}
                                </center>
                            </div>
                        </div>
                    ) : (
                        <div className='pokemon-card d-flex flex-column justify-content-between'>
                            {/* Token exchange info */}
                            <div className="token-exchange-info">
                                <div>
                                    <img src={logoToken} style={{ width: "30px", marginRight: "6px", marginLeft: "5px", marginTop: "-5px" }} alt={SETTINGS.tokenSymbol} />
                                    1 {SETTINGS.tokenSymbol}&nbsp;&nbsp;&nbsp;=
                                    <img src={SETTINGS.usdtIcon} style={{ width: "30px", marginRight: "8px", marginLeft: "10px", marginTop: "-5px" }} alt={"USDC"} />
                                    0.15 USDC
                                </div>
                            </div>
                            <Alert variant="secondary">
                                <div className='py-md-3 py-2 px-md-2 px-1 text-center' style={{ margin: "0px 0px" }}>
                                    <h5>Connect wallet to buy ${SETTINGS.tokenSymbol} tokens</h5>
                                    <br />
                                    <ConnectButton />
                                </div>
                            </Alert>
                        </div>
                    )}
                </Col>
                {/* CTA */} 
                <Col xs={12} md={8} lg={4} xl={3} className='mt-lg-0 mt-3 offset-lg-0 offset-md-2 offset-0'>
                    <div className="urgency-message p-3 mb-3" style={{
                        border: '2px solid #f5c6cb',
                        borderRadius: '10px',
                        backgroundColor: '#f8d7da',
                        color: '#721c24',
                        textAlign: 'center',
                    }}>
                        <div style={{ marginBottom: '8px', marginTop: "2px" }}>
                            <img src={clockIcon} alt="Clock Icon" style={{ width: '120px', height: '120px' }} />
                        </div>
                        <h4>Act now!</h4>
                        <p className='small'><b>The price of QVRS tokens is set to increase soon.</b></p>
                        <p className='small'> Currently, <b>1 {SETTINGS.tokenSymbol} is only 0.15 USDC</b>, but this won't last long. Secure your tokens now before the price goes up!</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default TokenSale;