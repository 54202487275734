import React, { useState, useEffect } from "react";
import { ethers, Contract } from "ethers";
import { Button, Form, Spinner, Alert, Modal, Image, Card, Row, Col } from "react-bootstrap";
import NFTMarketplaceABI from "../abis/NFTMarketplaceABI.json";
import CollectionABI from "../abis/CollectionABI.json";
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";

function Marketplace({ provider, isConnected }) {
  const [listings, setListings] = useState([]);
  const [selectedTokenId, setSelectedTokenId] = useState("");
  const [price, setPrice] = useState("");
  const [isERC20Payment, setIsERC20Payment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [ownedNFTs, setOwnedNFTs] = useState([]);

  useEffect(() => {
    if (isConnected) {
      fetchListings();
      fetchOwnedNFTs();
    }
  }, [isConnected]);

  const marketplaceAddress = SETTINGS.marketplaceAddress;

  const fetchListings = async () => {
    setLoading(true);
    try {
      const ethersProvider = new ethers.BrowserProvider(provider);
      const marketplaceContract = new Contract(
        marketplaceAddress,
        NFTMarketplaceABI,
        ethersProvider
      );
      const listings = await marketplaceContract.getAllListings(
        SETTINGS.collection
      );
      const listingsArr = [];
      for (let i = 0; i < listings.length; i++) {
        const tokenType = await getTokenType(listings[i].tokenId);
        listingsArr.push({
          collection: listings[i].collection,
          tokenId: listings[i].tokenId,
          tokenType: tokenType,
          price: listings[i].price,
          collection: listings[i].collection,
          isERC20Payment: listings[i].isERC20Payment,
          seller: listings[i].seller,
          active: listings[i].active,
          isERC20Payment: listings[i].isERC20Payment,
        });
      }
      console.log(listingsArr);
      setListings(listingsArr);
    } catch (error) {
      console.error("Error fetching listings:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTokenType = async (tokeknId) => {
    try {
      const ethersProvider = new ethers.BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nftContract = new Contract(
        SETTINGS.collection,
        CollectionABI,
        signer
      );
      const tokeknType = await nftContract.tokenTypes(tokeknId);
      return tokeknType.toString();
    } catch (error) {
      console.error("Error fetching owned NFTs:", error);
      return -1;
    }
  };

  const fetchOwnedNFTs = async () => {
    try {
      const ethersProvider = new ethers.BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nftContract = new Contract(
        SETTINGS.collection,
        CollectionABI,
        signer
      );
      const address = await signer.getAddress();
      const balance = await nftContract.balanceOf(address);

      const ownedNFTs = [];
      for (let i = 0; i < balance; i++) {
        const tokenIdBigIng = await nftContract.tokenOfOwnerByIndex(address, i);
        const tokenId = tokenIdBigIng.toString();

        ownedNFTs.push({ tokenId });
      }
      setOwnedNFTs(ownedNFTs);
    } catch (error) {
      console.error("Error fetching owned NFTs:", error);
    }
  };

  const listNFT = async () => {
    if (!selectedTokenId || !price) {
      setMessage("Token ID and Price are required.");
      return;
    }

    setLoading(true);
    setMessage("");
    try {
      const ethersProvider = new ethers.BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const marketplaceContract = new Contract(
        marketplaceAddress,
        NFTMarketplaceABI,
        signer
      );

      const nftContract = new Contract(
        SETTINGS.collection,
        CollectionABI,
        signer
      );
      console.log(selectedTokenId);
      const tx_app = await nftContract.approve(marketplaceAddress, selectedTokenId);
      await tx_app.wait();
      console.log(price);
      console.log(ethers.parseUnits(price, "ether"));
      const tx = await marketplaceContract.listNFT(
        SETTINGS.collection,
        selectedTokenId,
        ethers.parseUnits(price, "ether"),
        isERC20Payment
      );
      await tx.wait();

      setMessage("NFT listed successfully!");
      fetchListings();
    } catch (error) {
      console.error("Error listing NFT:", error);
      setMessage("Failed to list NFT.");
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const buyNFT = async (collection, tokenId, price, isERC20) => {
    setLoading(true);
    setMessage("");
    try {
      const ethersProvider = new ethers.BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const marketplaceContract = new Contract(
        marketplaceAddress,
        NFTMarketplaceABI,
        signer
      );

      if (isERC20) {
        const erc20Contract = new Contract(
          SETTINGS.tokenAddress,
          ERC20_ABI,
          signer
        );
        const allowance = await erc20Contract.allowance(
          await signer.getAddress(),
          marketplaceAddress
        );

        if (allowance.lt(price)) {
          const tx_app = await erc20Contract.approve(marketplaceAddress, price);
          await tx_app.wait();
        }

        const tx = await marketplaceContract.buyNFT(collection, tokenId);
        await tx.wait();
      } else {
        const tx = await marketplaceContract.buyNFT(collection, tokenId, {
          value: price,
        });
        await tx.wait();
      }

      setMessage("NFT purchased successfully!");
      fetchListings();
    } catch (error) {
      console.error("Error buying NFT:", error);
      setMessage("Failed to purchase NFT.");
    } finally {
      setLoading(false);
    }
  };

  const delistNFT = async (collection, tokenId) => {
    setLoading(true);
    setMessage("");
    try {
      const ethersProvider = new ethers.BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const marketplaceContract = new Contract(
        marketplaceAddress,
        NFTMarketplaceABI,
        signer
      );

      const tx = await marketplaceContract.delistNFT(collection, tokenId);
      await tx.wait();

      setMessage("NFT delisted successfully!");
      fetchListings();
    } catch (error) {
      console.error("Error delisting NFT:", error);
      setMessage("Failed to delist NFT.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>NFT Marketplace</h2>

      {message && <Alert variant="info">{message}</Alert>}

      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        disabled={loading}
      >
        {loading ? <Spinner animation="border" size="sm" /> : "List NFT"}
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>List NFT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="ownedNFTs">
              <Form.Label>Select Token ID</Form.Label>
              <Form.Control
                as="select"
                value={selectedTokenId}
                onChange={(e) => setSelectedTokenId(e.target.value)}
              >
                <option value="">Select your NFT</option>
                {ownedNFTs.map((nft) => (
                  <option key={nft.tokenId} value={nft.tokenId}>
                    Token ID: {nft.tokenId}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="paymentType">
              <Form.Check
                type="checkbox"
                label="Accept ERC20 Payment"
                checked={isERC20Payment}
                onChange={(e) => setIsERC20Payment(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={listNFT}>
            {loading ? <Spinner animation="border" size="sm" /> : "List NFT"}
          </Button>
        </Modal.Footer>
      </Modal>

      <h3 className="mt-4">Listings</h3>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Row>
          {listings.map((listing, index) => (
            <Col key={index} xs={6} md={4} lg={3} className="mb-4">
              <Card>
                <Card.Img
                  variant="top"
                  src={SETTINGS.NODE_INFO[listing.tokenType].imgUrl}
                />
                <Card.Body>
                  <Card.Title>Token ID: {listing.tokenId.toString()}</Card.Title>
                  <Card.Text>
                    <strong>Price:</strong> {ethers.formatEther(listing.price, "ether")} ETH
                  </Card.Text>
                  <Card.Text>
                    <strong>Seller:</strong> {listing.seller}
                  </Card.Text>
                  <Card.Text>
                    <strong>Payment Type:</strong> {listing.isERC20Payment ? "ERC20" : "Native"}
                  </Card.Text>
                  <Button
                    variant="success"
                    onClick={() =>
                      buyNFT(
                        listing.collection,
                        listing.tokenId,
                        listing.price,
                        listing.isERC20Payment
                      )
                    }
                    className="me-2"
                  >
                    Buy
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() =>
                      delistNFT(listing.collection, listing.tokenId)
                    }
                  >
                    Delist
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

export default Marketplace;
