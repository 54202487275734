import React from 'react';
import SETTINGS from '../SETTINGS';

const NodeSaleAnnouncement = () => {
  return (
    <div style={{ textAlign: 'left', }}>
      <h3 className='text-start'>🌐 QNODE SALE IS HERE! 📣</h3>

      <p>Hey Qvrse fam! We're super excited to announce the launch of the QNODES sale. 🚀</p>

      <h5 className='mt-4 mb-2'>WHAT IS THE QNODES SALE? 🤔</h5>
      <p>The QNODES sale offers you a chance to buy the Qvrse node and contribute to the Qvrse token DEX liquidity pool. By owning QNODES, you get benefits and become a player in the network's operations.🛠️</p>

      <h5 className='mt-4 mb-2'>WHY ARE WE LAUNCHING QNODES? 🌟</h5>
      <p>We’re building a gamified experience to bring new users to Web3. This collaborative approach is all about shaping the future of Web3 commerce together. 🌍✨</p>

      <h5 className='mt-4 mb-2'>KEY DETAILS ABOUT THE QNODES SALE 🗓️</h5>
      <p>The Node Sale kicks off before the {SETTINGS.tokenSymbol}E 1.0 launch. Here’s the scoop:</p>
      <ul>
        <li><strong>Total QNODES Available:</strong> 20,000</li>
        <li><strong>QNODES Rewards:</strong> 25% of the total Qvrse token supply is allocated for QNODE creation, along with additional community perks. 💰</li>
        <li><strong>Pricing Mechanism:</strong> The sale has 10 tiers, with prices going up each tier. 📈</li>
      </ul>

      <h5 className='mt-4 mb-2'>BENEFITS OF OWNING A QNODE 🎁</h5>
      <p>Joining the QNODES sale gets you these benefits:</p>
      <ul>
        <li><strong>Rewards:</strong> Keep earning rewards by holding QNODES. 🏆</li>
        <li><strong>Airdrop power:</strong> Get opportunities for more airdrops. 🎉</li>
        <li><strong>Secondary market:</strong> QNODE NFTs can be bought, sold, or traded on secondary markets. 🔄</li>
      </ul>

      <h5 className='mt-4 mb-2'>BE PART OF THE {SETTINGS.tokenSymbol}E MISSION🚀</h5>
      <p>Qvrse invites you to be a part of our mission to bring Web3 to mainstream through engaging and gamified experiences. 🌟 Discover new possibilities and shape the future with us!</p>
    </div>

  );
};

export default NodeSaleAnnouncement;
