
const SETTINGS = {
    nodeContract:"0xf4502f4F52b6374Dca355c10335Ac5d721Bf7DB1",
    ///nodeContract:"0xc08b21627A109CBb6BA56cA351b5A6Fe135EcD67",
    //nodeContract:"0xa6A7F2619409bD2F82E152440bE9f1C440c00D64",
    
    tokenSaleAddress:"0x093eF8ad83AbC9e22C7c2c19F17b4cb11c837CF2",
    mainContract:"0xd46928C9E6A653A6c1C6Bccfc75eE9De15Ec8Eea",
    //mainContract:"0xf9f377cb1FBCaE718F6E6b7b4A5bde7f6de0c48A",
    usdtAddress:"0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    tokenAddress:"0x14d1996D29616C1fb589522ce6A81a583f1c32bE",
    stakingContractQtech:"0x41618517cd4474938EE0b7ddA183037735f4471E",
    stakingContract:"0x07875c189656289040f865F49857f123cc74946D",
    //stakingContract:"0x5aAEBb9543725F94785a3945b35319aD275aEc67",
    //stakingContract:"0x84DBe5DdD187eA681E3528BAB49C023Ef05072a2",
    owner: ["0x0b71f90423fd9b824708BdFAA4b6FfcB95E650c0", "0xA9Fd4abD11B4533405ae71e8fC12CC7A10124f42"],
    nativeSymbol: "MATIC",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    usdtIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    tokenSymbol: "QVRS",
    collectionSymbol: "QNODE",
    openSeaLink: "https://opensea.io/collection/qvrsworlds",
    spatialLink: "https://www.spatial.io/s/QvrseStation-6655c097311b7a21439823eb?share=3953462147396508479",
    dataFetcherContract: "0x2786EcFFd234BCb82E5e1d797e8E8595E64C8fc4",
    marketplaceAddress: "0x7B08167229876E29c656FCEe0fCa75dDcFA143d8",
    collection: "0xc0F17dD7f19710532432302631e98e4458d5D708",
    ///collection: "0x9BC7090AF27aDE9029027Ffe9bB8aba60fB54323",
    //collection: "0xd4De211A1017C340538e23c1dB9A74CcEbfc0598",
    ////collection: "0xaD3546a9AbF7519FCE17d009b37A82b5A65ea085",
    //collection: "0x809A2Fc4a0855DAad16C9674010CbC9f7706Ca8A",
    //collection: "0x1dB3498AE599AcBdbC4317bdF13AF3fA26e65DE4",
    //collection: "0x3A97d183D2658938C854EdCb5579CCE605DBc59B",
    //collection: "0x44B16678A1Be0C30FDA4CEF3aB01f8Ac3de13f9D",
    DAILY_TOKENS: 0.345,
    MAX_SUPPLY: 20000,
    AP_PRICE: 2,
    END_TIME: 1843121631 * 1000,
    NEXT_AIRDROP: 1725174000 * 1000,
    NODE_INFO:{
        "1":{
                airdrop_power: 1,
                title: 'QNODE 1', 
                level: 'Level 1', 
                dailyRewards: 0.345, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet.png", 
                craftElements:[],
                price: 25,
                buyCurrency:"USDC"
            },
        "2":{
                airdrop_power: 3,
                title: 'QNODE 2', 
                level: 'Level 2', 
                dailyRewards: 0.345 * 2, 
                refferalPercent: 2, 
                imgUrl: "https://node.qvrse.io/planet1.png", 
                craftElements:["https://node.qvrse.io/planet.png", "https://node.qvrse.io/planet.png"],
                price: 0,
                buyCurrency:""
            },
        "3":{
                airdrop_power: 7,
                title: 'QNODE 3',
                level: 'Level 3',  
                dailyRewards: 0.345 * 4, 
                refferalPercent: 3, 
                imgUrl: "https://node.qvrse.io/planet2.png", 
                craftElements:["https://node.qvrse.io/planet1.png", "https://node.qvrse.io/planet1.png", "https://node.qvrse.io/planet10.png"],
                price: 0,
                buyCurrency:""
            },
        "4":{
                airdrop_power: 16,
                title: 'QNODE 4', 
                level: 'Level 4', 
                dailyRewards: 0.345 * 8, 
                refferalPercent: 5, 
                imgUrl: "https://node.qvrse.io/planet3.png", 
                craftElements:["https://node.qvrse.io/planet2.png", "https://node.qvrse.io/planet2.png", "https://node.qvrse.io/planet10.png", "https://node.qvrse.io/planet20.png"],
                price: 0,
                buyCurrency:""
            },
        "5":{
                airdrop_power: 50,
                title: 'QNODE 5', 
                level: 'Level 5', 
                dailyRewards: 0.345 * 16, 
                refferalPercent: 8, 
                imgUrl: "https://node.qvrse.io/planet4.png", 
                craftElements:["https://node.qvrse.io/planet3.png", "https://node.qvrse.io/planet3.png", "https://node.qvrse.io/planet20.png", "https://node.qvrse.io/planet42.png"],
                price: 0,
                buyCurrency:""
            },
        "1337":{
                airdrop_power: 120,
                title: 'QNODE 1337', 
                level: 'Level 1337', 
                dailyRewards: 0.345 * 32, 
                refferalPercent: 10, 
                imgUrl: "https://node.qvrse.io/planet1337.png", 
                craftElements:["https://node.qvrse.io/planet4.png", "https://node.qvrse.io/planet4.png", "https://node.qvrse.io/planet42.png", "https://node.qvrse.io/planet42.png"],
                price: 0,
                buyCurrency:""
            },
        "20":{
                airdrop_power: 2,
                title: 'Fire potion', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet20.png", 
                craftElements:[],
                price: 20,
                buyCurrency:"QVRS"
            },
        "42":{
                airdrop_power: 4,
                title: 'Air potion', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet42.png", 
                craftElements:[],
                price: 42,
                buyCurrency:"QVRS"
            },
        "10":{
                airdrop_power: 0,
                title: 'Ether potion', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet10.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"QVRS"
            },
        "11":{
                airdrop_power: 1,
                title: 'Water potion', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet11.png", 
                craftElements:["https://node.qvrse.io/planet10.png", "https://node.qvrse.io/planet10.png", "https://node.qvrse.io/planet10.png", "https://node.qvrse.io/planet10.png"],
                price: 0,
                buyCurrency:""
            },
        "12":{
                airdrop_power: 3,
                title: 'Earth potion', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet12.png", 
                craftElements:["https://node.qvrse.io/planet11.png", "https://node.qvrse.io/planet11.png", "https://node.qvrse.io/planet10.png", "https://node.qvrse.io/planet10.png"],
                price: 0,
                buyCurrency:""
            },
            
        "14":{
                airdrop_power: 15,
                title: 'Earth Guardian', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet14.png", 
                craftElements:["https://node.qvrse.io/planet12.png", "https://node.qvrse.io/planet12.png"],
                price: 0,
                buyCurrency:""
            },
        "15":{
                airdrop_power: 15,
                title: 'Water Guardian', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet15.png", 
                craftElements:["https://node.qvrse.io/planet11.png", "https://node.qvrse.io/planet11.png", "https://node.qvrse.io/planet11.png", "https://node.qvrse.io/planet11.png"],
                price: 0,
                buyCurrency:""
            },
        "16":{
                airdrop_power: 15,
                title: 'Air Guardian', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet16.png", 
                craftElements:["https://node.qvrse.io/planet10.png", "https://node.qvrse.io/planet10.png", "https://node.qvrse.io/planet42.png"],
                price: 0,
                buyCurrency:""
            },
        "17":{
                airdrop_power: 25,
                title: 'Fire Guardian', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.qvrse.io/planet17.png", 
                craftElements:["https://node.qvrse.io/planet20.png", "https://node.qvrse.io/planet20.png", "https://node.qvrse.io/planet15.png"],
                price: 0,
                buyCurrency:""
            },
            
       
    },
    CRAFT_DICT:{
        "1_1_0_0": ["https://node.qvrse.io/planet1.png", 2],
        "2_2_10_0": ["https://node.qvrse.io/planet2.png", 3],
        "3_3_10_20": ["https://node.qvrse.io/planet3.png", 4],
        "4_4_20_42": ["https://node.qvrse.io/planet4.png", 5],
        "5_5_42_42": ["https://node.qvrse.io/planet1337.png", 1337],
        "10_10_10_10": ["https://node.qvrse.io/planet11.png", 11],
        "11_11_10_10": ["https://node.qvrse.io/planet12.png", 12],
        "12_12_0_0": ["https://node.qvrse.io/planet14.png", 14],
        "11_11_11_11": ["https://node.qvrse.io/planet15.png", 15],
        "10_10_42_0": ["https://node.qvrse.io/planet16.png", 16],
        "20_20_14_0": ["https://node.qvrse.io/planet17.png", 17],
      }


};
export default SETTINGS;
