import React, { useEffect, useState } from 'react';
import parachuteImg from '../parachute.png';
import logoToken from '../logoToken.svg';
import { ERC20_ABI } from "../abis/erc20";
import QtechStakingABI from '../abis/QtechStakingABI.json';
import NodesABI from '../abis/NodesABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Button, Alert, Card } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import LevelsLegend from '../components/LevelsLegend';
import CoinSpin from '../components/CoinSpin';
import RefferalComponent from '../components/RefferalComponent';
import LevelComponent from '../components/LevelComponent';
import { useNavigate } from 'react-router-dom';
import StripeLoaderImg from '../stripe_loader.svg';
import { BiInfoCircle, BiBox, BiCoin, BiCalendar, BiCheckCircle } from 'react-icons/bi';
import metaMaskIcon from '../metaMask.svg';

function Dashboard({ provider, address, isConnected }) {
  const [usdtBalance, setUsdtBalance] = useState('0.00');
  const [tokenBalance, setTokenBalance] = useState('0.00');
  const [airdropPower, setAirdropPower] = useState('0.00');
  const [totalNodesAmount, setTotalNodesAmount] = useState('0.00');
  const [qvrsStakes, setQvrsStakes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const navigate = useNavigate();
  const [userLevel, setUserLevel] = useState(1);
  const trunTokenAddress = `${SETTINGS.tokenAddress.slice(0, 4)}...${SETTINGS.tokenAddress.slice(-1 * (4))}`;
  const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
  const getUserLevel = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const collectionContract = new Contract(
      SETTINGS.collection,
      CollectionABI,
      signer
    );
    const level = await collectionContract.levels(signer.address);
    if (parseInt(level) > 1) {
      setUserLevel(parseInt(level));
    }
  };
  const importTokenToMetamask = async () => {
    const tokenAddress = SETTINGS.tokenAddress;
    const tokenSymbol = SETTINGS.tokenSymbol;
    const tokenDecimals = 18;
    const tokenImage = logoToken;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log("Error importing token to Metamask:", error);
    }
  };

  const countUserNFTs = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);
      const nftsForUser = await contract.getAllNFTsForUser(address);
      const serialized = JSON.stringify(nftsForUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const nfts = JSON.parse(serialized);
      const nftDataPromises = nfts.map(async (nft) => {
        const tokenType = await collectionContract.tokenTypes(parseInt(nft[1]));
        return {
          rawAirdropPower: parseFloat(SETTINGS.NODE_INFO[tokenType.toString()].airdrop_power),
        };
      });
      const nftData = await Promise.all(nftDataPromises);
      let totalAirdropPow = 0;
      nftData.forEach((temp) => {
        totalAirdropPow += temp.rawAirdropPower;
      });
      setTotalNodesAmount(nftsForUser.length);
      setAirdropPower(totalAirdropPow.toFixed(2));
    }
    catch (e) {
      console.log("count user nfts error");
      console.log(e);
    }
  }

  async function getBalance() {
    if (!isConnected)
      setLoadingBalance(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      //const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
      //const USDTBalance = await USDTContract.balanceOf(address);
      //setUsdtBalance(parseFloat(formatUnits(USDTBalance, 6)).toFixed(2));
      const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(address);
      setTokenBalance(parseFloat(formatUnits(TokenBalance, 18)).toFixed(2));
      await getUserLevel();
      await countUserNFTs();
    }
    catch (error) {
      console.log("error getBalance");
      console.log(error);
    }
    setLoadingBalance(false);
  }
  const openBuyToken = () => {
    navigate('/token');
  }
  const openStakeToken = () => {
    navigate('/staking');
  }
  const openNodesList = () => {
    navigate('/list');
  }
  const openFAQs = () => {
    navigate('/FAQs');
  }
  const openShop = () => {
    navigate('/store');
  }
  const closeStake = async (stakeId) => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      setLoading(true);
      setTxMessage("Closing Stake...");
      const contract = new Contract(SETTINGS.stakingContractQtech, QtechStakingABI, signer);
      const tx = await contract.completeStake(parseInt(stakeId));
      await tx.wait();
      setTxMessage("Stake closed!");
    }
    catch (error) {
      console.error("Error closing stake:", error);
      setTxMessage("Failed to close stake. Please try again.");
    }
    finally {
      await getBalance();
      await getStakesQvrse();
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };
  async function getStakesQvrse() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(SETTINGS.stakingContractQtech, QtechStakingABI, signer);
      const stakesUser = await contract.getInvestorStakes(address);
      const serialized = JSON.stringify(stakesUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const stakes = JSON.parse(serialized);
      let stakesArr = [];
      for (let i = 0; i < stakes.length; i++) {
        if (stakes[i]["8"] == 1) continue
        stakesArr.push(
          {
            id: stakes[i]["0"],
            amount: formatUnits(stakes[i]["3"], 18),
            time: stakes[i]["5"],
            status: stakes[i]["8"],
          });
      }
      setQvrsStakes(stakesArr);
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (isConnected) {
      getBalance();
      getStakesQvrse();
    } else {
      setQvrsStakes([]);
      setTokenBalance("0.00");
      setUserLevel(1);
    }
  }, [isConnected, provider]);

  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <img src={StripeLoaderImg} style={{ marginTop: "300px" }} />
        <br />
        <h6 className='loaderMsg'>{txMessage}</h6>
      </div>
    );
  }

  {/* Membership # */ }
  const renderStakes = () => {
    return qvrsStakes.map((stake, index) => (
      <Col key={index} sm={12} md={12} lg={12} xl={12} className="mb-2">
        <div className="nft-card h-100 pokemon-card additional-p py-4">
          <Row>
            <Col xs={12} md={6} lg={3} xl={2} className='d-flex align-items-center'>
              <b className='h5 my-0 mb-md-0 mb-2 mt-md-0 mt-1'>MEMBERSHIP #{stake.id}</b>
            </Col>
            <Col xs={12} md={6} lg={5} xl={6} className='d-flex align-items-center mt-md-0 mt-1'>
              <p className='my-0'>
                <small className='small'>Stake size: </small>
                <img src={logoToken} className='tokenIconColor' style={{ width: "20px", marginRight: "7px", marginTop: "-3px", marginLeft: "12px" }} alt={SETTINGS.tokenSymbol} />
                <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.amount)} {SETTINGS.tokenSymbol}</b>
              </p>
            </Col>
            <Col xs={12} md={6} lg={3} xl={2} className='offset-xl-2 offset-lg-1 d-flex justify-content-center'>
              <Button variant="primary mb-lg-0 mb-1 mt-lg-0 mt-4" onClick={() => closeStake(stake.id)} className='buton pulse-button'>
                CLOSE MEMBERSHIP
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    ));
  };

  return (
    <>
      {qvrsStakes.length > 0 ? (
        <>
          {/* Membership */}
          <Col>
            <Alert variant="primary mb-4">
              <h5 style={{ marginTop: "0px", color: "unset" }}>{SETTINGS.tokenSymbol}E MEMBERSHIP UPDATE</h5>
              <small>
                As we approach the launch of <b style={{ color: "unset" }}>Qvrse 1.0</b>, we are concluding the voting for new brands. With this transition, we are also wrapping up current membership stakes but are excited to introduce a <b style={{ color: "unset" }}>new staking opportunity</b>.
                <div className='mt-2'></div>
                Please follow these steps to proceed:
                <ul className='my-2'>
                  <li><b style={{ color: "unset" }}>Finalize Your Membership Stake</b>: Ensure you complete your existing membership stake and claim your {SETTINGS.tokenSymbol} tokens.</li>
                  <li><b style={{ color: "unset" }}>Initiate a New Stake</b>: Navigate to the <b style={{ color: "unset" }}>“Stake {SETTINGS.tokenSymbol}”</b> section. Here, you can open new stakes and access detailed information about durations and rewards.</li>
                </ul>
                Join us in embracing the future of Qvrse with these new opportunities!
              </small>
            </Alert>
          </Col>
          <Row>{renderStakes()}</Row>
        </>
      ) : (
        <> </>
      )}

      {/* Intro */}
      <Row className="mt-md-5 mt-4 mb-3 pb-0">
        <h3 className='text-center mb-4 pb-md-2 pb-0 d-none'>Check out!</h3>
        <Col className="mb-1">
          <div className='introSection d-flex justify-content-center align-items-center'>
          </div>
        </Col>
      </Row>

      <hr className='d-md-flex d-none' />

      {/* Title */}
      <h2 className='pageTitle d-none'>Dashboard</h2>

      {/* Levels Legend */}
      <LevelsLegend currentLevel={userLevel} />

      {/* Dashboard */}
      <Row className="mt-4 mb-md-3 mb-0 ">
        {/* QVRS token */}
        <Col sm={12} md={6} lg={4} xl={4} className="mb-1">
          <Card className='card-fix text-center pt-3 chat-message card-height'>
            <Card.Title className='mt-md-3 mt-2'>
              QVRS TOKEN
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-0'>
              <Row className='mt-4 align-items-center'>
                <Col xs={6} md={12}>
                  <center style={{ height: "130px" }}>
                    <CoinSpin />
                  </center>
                </Col>
                <Col xs={6} md={12} className='d-md-none d-flex'>
                  <Row className='mb-4'>
                    <Col xs={12} ><Button variant="primary buton d-flex align-items-center justify-content-center" onClick={openBuyToken} className='buton'>
                      <BiCoin className='me-1' /> BUY
                    </Button></Col>
                    <Col xs={12} ><Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openStakeToken} className='buton'>
                      <BiBox className='me-1' /> STAKE
                    </Button></Col>
                  </Row>
                </Col>
              </Row>
              <div className="mt-auto">
                <Col xs={6} md={12} className='d-md-block d-none'>
                  <Row className='mb-3'>
                    <Col><Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openBuyToken} className='buton'>
                      <BiCoin className='me-1' /> BUY
                    </Button></Col>
                    <Col><Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openStakeToken} className='buton' style={{ height: "42.5px", fontSize: "15px" }}>
                      <BiBox className='me-1' /> STAKE
                    </Button></Col>
                  </Row>
                </Col>
                <Button variant="primary" className='buton buton-secondary mb-0' onClick={importTokenToMetamask}>
                  IMPORT TO
                  <img src={metaMaskIcon} style={{ width: "20px", marginTop: "-3px", marginLeft: "5px" }} alt="metaMaskIcon" />
                </Button>
                <hr />
                <h6 className='fw-normal mb-0 pt-0 small'>
                  <small>Your balance:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
                  <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenBalance)} {SETTINGS.tokenSymbol}</b>
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* QNODES */}
        <Col sm={12} md={6} lg={4} xl={4} className="mb-1">
          <Card className='card-fix text-center pt-3 chat-message card-height'>
            <Card.Title className='mt-md-3 mt-2'>
              QNODES
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-0'>
              <LevelComponent provider={provider} isConnected={isConnected} nodeAmount={totalNodesAmount} />
              <Button variant="primary" className='buton mb-3' onClick={openNodesList} >
                MY QNODES
              </Button>
              <RefferalComponent provider={provider} isConnected={isConnected} nodeAmount={totalNodesAmount} />
            </Card.Body>
          </Card>
        </Col>
        {/* Airdrop power */}
        <Col sm={12} md={12} lg={4} xl={4} className="mb-md-1 mb-0">
          <Card className='card-fix text-center pt-3 chat-message card-height card-height-unset px-xl-2 px-0' style={{ zIndex: "-1" }}>
            <Card.Title className='mt-md-3 mt-2'>
              AIRDROP POWER
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-4'>
              <img src={parachuteImg} className='tokenIconColor floatAvatar mx-auto' style={{ width: "105px", marginBottom: "10px" }} alt={"AP"} />
              <div className='flex-grow-1'>
                <p className='py-1 px-xl-3 px-0 small mt-lg-5 mt-4 mb-xl-2 mb-md-1 mb-2'>
                  ** Use crafting to earn airdrop power. Each <img src={parachuteImg} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={"AP"} />
                  gets you
                  <img src={logoToken} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={SETTINGS.tokenSymbol} />
                  <b>{SETTINGS.AP_PRICE} {SETTINGS.tokenSymbol}</b> at next airdrop happening on:
                </p>
                <p className='d-flex align-items-center justify-content-center h6' style={{ color: 'seagreen' }}>
                  <BiCheckCircle className='me-1' /> 1. 9. 2024
                </p>
                <p className='d-flex align-items-center justify-content-center h4 mt-2 mb-0'><BiCalendar className='me-1' /> 1. 10. 2024</p>
              </div>
              <div className='mt-auto'>
                <hr />
                <h6 className='fw-normal mb-0 pt-0 small'>
                  <small>Your balance:</small> <img src={parachuteImg} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
                  <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(airdropPower)} AP</b>
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>

      </Row>

      <hr className='d-md-none d-flex' />

      {/* FAQs */}
      <Row className="mt-0 mb-md-4 mb-0 pb-md-2 pb-0">
        <Col xs={12} md={6} lg={4} xl={4} className="mb-1">
          <Card className='card-fix text-center pt-3 chat-message' style={{ height: "188px" }}>
            <Card.Body className='pt-md-4 pt-2 text-start'>
              <h6>**QVRS tokens</h6>
              <p className='small'>
                are the platform’s native currency. They are used for purchasing QNODES, crafting elements, staking, and as a payment method within the Qvrse ecosystem.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4} xl={4} className="mb-1">
          <Card className='card-fix text-center pt-3 chat-message' style={{ height: "188px", zIndex: "-1" }}>
            <Card.Body className='pt-md-4 pt-2 text-start'>
              <h6>**QNODES</h6>
              <p className='small'>are digital assets (NFTs) that can be purchased and crafted through the Qvrse token dapp.
                These assets are essential for advancing to higher levels and unlocking greater benefits.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={12} lg={4} xl={4} className="mb-md-1 mb-0">
          <Card className='card-fix text-center pt-3 chat-message' style={{ height: "188px" }}>
            <Card.Body className='pt-md-4 pt-2 text-start d-flex flex-column'>
              <h6>Check out FAQs</h6>
              <p className='small'>
                Learn more about how our Qvrse token works.
              </p>
              <Button variant="primary buton mt-auto d-flex align-items-center justify-content-center mb-2" onClick={openFAQs} className='buton'>
                <BiInfoCircle className='me-1' /> OPEN FAQs
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <hr />

      {/* Promo */}
      <Row className="mt-md-5 mt-4 mb-4 pb-2">
        <Col xs={12} lg={6} className="mb-1">
          <div className='nodeSection whyNodeSection'>
            <center>
              <h5 className='mt-md-4 mt-0'>GET YOUR <br className='d-md-none d-block'></br> QVRS TOKENS NOW!</h5>
              <p>Instant Purchase – No Referral Needed!</p>
              <a href="https://token.qvrse.io/token" onClick={openFAQs} className='buton mt-1' style={{ maxWidth: "300px" }}>
                BUY QVRS TOKEN
              </a>
            </center>
          </div>
        </Col>
        <Col xs={12} lg={6} className="mb-1">
          <div className='nodeSection'>
            <center>
              <h5 className='mt-0'>WHY DO YOU NEED QNODES?</h5>
              <p>
                1. Earn Qvrse tokens daily
                <br></br>
                2. Craft your nodes to increase rewards
                <br></br>
                3. Enhance your airdrop power
              </p>
              <a href="https://token.qvrse.io/store" onClick={openFAQs} className='buton mt-1' style={{ maxWidth: "300px" }}>
                BUY QNODES
              </a>
            </center>
          </div>
        </Col>
      </Row>

      <hr />

      {/* Video */}
      <Row className="mt-4 pt-md-4 pt-0 mb-4 pb-2">
        <h3 className='text-center mb-4 pb-md-3 pb-0'>CHECK OUT QUICK INTRODUCTION VIDEO</h3>
        <Col xs={12} md={12} lg={12} xl={12} className="mb-1">
          <video controls={true} style={{ maxWidth: "100%" }} className='video-introduction'>
            <source src="https://token.qvrse.io/ref/assets/img/QVRS-TOKEN.mp4" type="video/mp4" />
          </video>
        </Col>
      </Row>

      <hr />

      {/* Ecosystem */}
      <Row className="mt-md-5 mt-4 mb-5 pb-2">
        <h3 className='text-center mb-4 pb-md-2 pb-0'>Other parts of Qvrse ecosystem</h3>
        <Col xs={12} md={6} className="mb-1">
          <div className='platformSection'>
            <center>
              <h5>Qvrse.io</h5>
              <p>is a cutting-edge platform designed to integrate <br className='d-lg-block d-none'></br> branding and loyalty with a social media twist.</p>
              <a href="https://qvrse.io" target="_blank" onClick={openFAQs} className='buton mt-1' style={{ maxWidth: "300px" }}>
                GO TO QVRSE.IO
              </a>
            </center>
          </div>
        </Col>
        <Col xs={12} md={6} className="mb-1">
          <div className='platformSection stationSection'>
            <center>
              <h5>Qvrse station</h5>
              <p>Explore the station, complete the mission in the Qvrse album, <br className='d-xl-block d-none'></br> participate in the giveaways and visit all the connected worlds!</p>
              <a href="https://www.spatial.io/s/QvrseStation-6655c097311b7a21439823eb?share=3953462147396508479" target="_blank" className='buton mt-1' style={{ maxWidth: "300px" }}>
                GO TO QVRSE STATION
              </a>
            </center>
          </div>
        </Col>
      </Row>

    </>
  );
}

export default Dashboard;
